import { PartialRecord } from '~/types';
import { RequestType } from './modules/Request';

export type CurrencyType = 'usd'

export type iapProducts = {
	type: RequestType,
	amount: PartialRecord<CurrencyType, number[]>
}

export type iapParseResponse = PartialRecord<
	RequestType,
	iapProducts
>

export const parseProducts = (products: string[]): iapParseResponse => {

	const iapProducts: iapParseResponse = {};

	products.forEach(
		(product) => {

			const [ type, currency, amount ] = product.split('.').splice(-3) as [
				RequestType,
				CurrencyType,
				number,
			];

			if (!iapProducts[type]) {
				iapProducts[type] = { type, amount: {} };
			}

			if (!iapProducts[type]!.amount[currency]) {
				iapProducts[type]!.amount[currency] = [];
			}

			iapProducts[type]!.amount[currency]!.push(+ amount);

		}
	);

	return iapProducts;

}

export const getProductByAmount = (type: RequestType, amount: number, currency: CurrencyType) => {

	return `com.minnesotacollective.application.${type}.${currency}.${amount}`;

}
