import { Option } from '~/types';

export const OptionParam = (options: Option[], defaultValue?: string) => {

	const optionsKeys = options.map(({ value }) => value);

	function encode (str: string | undefined): string | undefined {
		if (!str || !str.length) {
			return undefined;
		}
		if (defaultValue && defaultValue === str) {
			return undefined;
		}
		return str;
	}

	function decode (str: string | (string | null)[] | null | undefined): string | undefined {
		if (str === null || typeof str !== 'string' || !str.length || optionsKeys.indexOf(str) === -1) {
			return undefined;
		}
		if (defaultValue && defaultValue === str) {
			return defaultValue;
		}
		return str;
	}

	return { encode, decode, };

}


export const SearchParam = {
	encode: (str: string | undefined): string | undefined => {
		if (!str || !str.length) {
			return undefined;
		}
		return str;
	},
	decode: (str: string | (string | null)[] | null | undefined): string | undefined => {
		if (str === null || typeof str !== 'string' || !str.length) {
			return undefined;
		}
		return str;
	},
};
